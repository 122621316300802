import { Component, Injectable, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShareButtonComponent } from 'src/app/components/share-button/share-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(private _snackBar:MatSnackBar, private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
  }

  shareInSocialMedia():void {
  
   const bottomSheetRef =  this._bottomSheet.open(ShareButtonComponent)
   bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
    if (dataFromChild == true) {
       this._snackBar.open('Enlace copiado a portapapeles','Cerrar',{
        duration:2500
       });
    }
  
   });
  }

}
