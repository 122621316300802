<mat-nav-list>

    <a href="https://api.whatsapp.com/send?text={{postTitle}} {{postUrl}}" target="_blank" mat-list-item (click)="openLink()">
        <mat-icon mat-list-icon svgIcon="whatsapp"></mat-icon>
        <span mat-line>WhatsApp</span>
        <span mat-line>Compartir en WhatsApp</span>
    </a>

    <a href="https://www.facebook.com/sharer/sharer.php?u={{postUrl}}" target="_blank" mat-list-item (click)="openLink()">
        <mat-icon mat-list-icon svgIcon="facebook"></mat-icon>
        <span mat-line>Facebook</span>
        <span mat-line>Compatir en Facebook</span>
    </a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{postUrl}}" target="_blank" mat-list-item (click)="openLink()">
        <mat-icon mat-list-icon svgIcon="linkedin"></mat-icon>
        <span mat-line>LinkedIn</span>
        <span mat-line>Compartir en LinkedIn</span>
    </a>
    <a href="https://twitter.com/intent/tweet?url={{postUrl}}&text={{postTitle}}&via=MecateD&hastag=marketing" target="_blank" mat-list-item (click)="openLink()">
        <mat-icon mat-list-icon svgIcon="twitter"></mat-icon>
        <span mat-line>Twitter</span>
        <span mat-line>Compartir en Twitter</span>
    </a>
    <a href="http://www.pinterest.com/pin/create/button/?url={{postUrl}}&media=https://app.mecatedigital.com.mx/assets/img/Calculadora-Inbound-Marketing.jpg" target="_blank " mat-list-item (click)="openLink()">
        <mat-icon mat-list-icon svgIcon="pinterest"></mat-icon>
        <span mat-line>Pinterest</span>
        <span mat-line>Compartir en Pinterest</span>
    </a>
    <a mat-list-item (click)="copyLink()">
        <mat-icon mat-list-icon>content_copy</mat-icon>
        <span mat-line>Copiar Enlace</span>
        <span mat-line>Copiar Enlace en portapapeles</span>
    </a>
</mat-nav-list>