import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { Title } from '@angular/platform-browser'
import { Clipboard } from '@angular/cdk/clipboard'

const WHATSAPP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 360 362" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M307.546 52.5655C273.709 18.685 228.706 0.0171895 180.756 0C81.951 0 1.53846 80.404 1.50408 179.235C1.48689 210.829 9.74646 241.667 25.4319 268.844L0 361.736L95.0236 336.811C121.203 351.096 150.683 358.616 180.679 358.625H180.756C279.544 358.625 359.966 278.212 360 179.381C360.017 131.483 341.392 86.4547 307.546 52.5741V52.5655ZM180.756 328.354H180.696C153.966 328.346 127.744 321.16 104.865 307.589L99.4242 304.358L43.034 319.149L58.0834 264.168L54.5423 258.53C39.6304 234.809 31.749 207.391 31.7662 179.244C31.8006 97.1036 98.6334 30.2707 180.817 30.2707C220.61 30.2879 258.015 45.8015 286.145 73.9665C314.276 102.123 329.755 139.562 329.738 179.364C329.703 261.513 262.871 328.346 180.756 328.346V328.354ZM262.475 216.777C257.997 214.534 235.978 203.704 231.869 202.209C227.761 200.713 224.779 199.966 221.796 204.452C218.814 208.939 210.228 219.029 207.615 222.011C205.002 225.002 202.389 225.372 197.911 223.128C193.434 220.885 179.003 216.158 161.891 200.902C148.578 189.024 139.587 174.362 136.975 169.875C134.362 165.389 136.7 162.965 138.934 160.739C140.945 158.728 143.412 155.505 145.655 152.892C147.899 150.279 148.638 148.406 150.133 145.423C151.629 142.432 150.881 139.82 149.764 137.576C148.646 135.333 139.691 113.287 135.952 104.323C132.316 95.5909 128.621 96.777 125.879 96.6309C123.266 96.5019 120.284 96.4762 117.293 96.4762C114.302 96.4762 109.454 97.5935 105.346 102.08C101.238 106.566 89.6691 117.404 89.6691 139.441C89.6691 161.478 105.716 182.785 107.959 185.776C110.202 188.767 139.544 234.001 184.469 253.408C195.153 258.023 203.498 260.782 210.004 262.845C220.731 266.257 230.494 265.776 238.212 264.624C246.816 263.335 264.71 253.786 268.44 243.326C272.17 232.866 272.17 223.893 271.053 222.028C269.936 220.163 266.945 219.037 262.467 216.794L262.475 216.777Z" fill="#111B21"/>
</svg>`

const LINKEDIN = `<svg xmlns="http://www.w3.org/\sharer.php2000/svg" class="w-6 h-6" id="Capa_1" viewBox="0 0 300 300">
  <path d="M23,53.5c0-16.84,13.66-30.5,30.5-30.5s30.5,13.66,30.5,30.5-13.66,30.79-30.5,30.79-30.5-13.95-30.5-30.79Z"/><rect x="27.19" y="107.42" width="52.66" height="169.58"/>
  <path d="M224.4,277v-82.55c0-19.67-.4-44.9-27.39-44.9s-31.57,21.38-31.57,43.48v83.97h-52.6V107.42h50.5v23.13h.74c7.03-13.32,24.2-27.39,49.83-27.39,53.3,0,63.09,35.1,63.09,80.67v93.15h-52.6Z"/></svg>
`

const FACEBOOK = `
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" id="Ebene_1" viewBox="0 0 1024 1024"><path d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78v-357.78h-130v-148h130v-112.8c0-128.32,76.44-199.2,193.39-199.2,56.02,0,114.61,10,114.61,10v126h-64.56c-63.6,0-83.44,39.47-83.44,79.96v96.04h142l-22.7,148h-119.3v357.78c244.77-38.41,432-250.22,432-505.78Z"/><path d="M711.3,660l22.7-148h-142v-96.04c0-40.49,19.84-79.96,83.44-79.96h64.56v-126s-58.59-10-114.61-10c-116.95,0-193.39,70.88-193.39,199.2v112.8h-130v148h130v357.78c26.07,4.09,52.78,6.22,80,6.22s53.93-2.13,80-6.22v-357.78h119.3Z" style="fill:#fff;"/></svg>
`

const TWITTER = `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Logo" x="0px" y="0px" viewBox="0 0 248 204" style="enable-background:new 0 0 248 204;" xml:space="preserve">
<g id="Logo_1_">
	<path id="white_background" d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04   C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66   c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64   c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76   c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26   c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"/>
</g>
</svg>`

const PINTEREST = `
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"  viewBox="0 0 100 100" fill="none">
<path d="M49.9794 0C22.3365 0 0 22.3776 0 49.9794C0 71.1641 13.1633 89.2637 31.7565 96.5446C31.304 92.5956 30.9338 86.5076 31.921 82.1884C32.826 78.2805 37.7622 57.3427 37.7622 57.3427C37.7622 57.3427 36.2814 54.3398 36.2814 49.9383C36.2814 42.9864 40.3126 37.8034 45.3311 37.8034C49.6092 37.8034 51.666 41.0119 51.666 44.8375C51.666 49.1156 48.951 55.5327 47.5113 61.4973C46.3184 66.4747 50.0206 70.5471 54.9157 70.5471C63.8009 70.5471 70.6294 61.1682 70.6294 47.6759C70.6294 35.7055 62.0321 27.355 49.7326 27.355C35.4998 27.355 27.1493 38.009 27.1493 49.0333C27.1493 53.3114 28.7947 57.9185 30.8515 60.4278C31.2629 60.9214 31.304 61.3739 31.1806 61.8675C30.8104 63.4307 29.9465 66.8449 29.782 67.5442C29.5763 68.4492 29.0415 68.6549 28.0954 68.2024C21.9251 65.2406 18.0584 56.1086 18.0584 48.7865C18.0584 33.0317 29.494 18.552 51.0901 18.552C68.4081 18.552 81.9005 30.8926 81.9005 47.429C81.9005 64.6647 71.0407 78.5274 55.9852 78.5274C50.9255 78.5274 46.1538 75.8947 44.5496 72.7684C44.5496 72.7684 42.0403 82.3118 41.4233 84.6565C40.3126 89.0169 37.2686 94.4467 35.2118 97.7787C39.9013 99.2184 44.8375 100 50.0206 100C77.6224 100 100 77.6224 100 50.0206C99.9589 22.3776 77.5812 0 49.9794 0Z" fill="black"/>
</svg>
`

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent implements OnInit {
  postTitle: string = ''
  postUrl: string = ''
  imgUrl: string = 'assets/img/Calculadora-Inbound-Marketing.png'
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _bottomSheetRef: MatBottomSheetRef<ShareButtonComponent>,
    private title: Title,
    private clipboard: Clipboard,
    @Inject(DOCUMENT) private document: Document,
  ) {
    iconRegistry.addSvgIconLiteral(
      'whatsapp',
      sanitizer.bypassSecurityTrustHtml(WHATSAPP_ICON),
    )
    iconRegistry.addSvgIconLiteral(
      'linkedin',
      sanitizer.bypassSecurityTrustHtml(LINKEDIN),
    )
    iconRegistry.addSvgIconLiteral(
      'facebook',
      sanitizer.bypassSecurityTrustHtml(FACEBOOK),
    )
    iconRegistry.addSvgIconLiteral(
      'twitter',
      sanitizer.bypassSecurityTrustHtml(TWITTER),
    )
    iconRegistry.addSvgIconLiteral(
      'pinterest',
      sanitizer.bypassSecurityTrustHtml(PINTEREST),
    )
  }

  ngOnInit(): void {}

  openLink(): void {
    this.title.setTitle('Calculadora Inbound Marketing')
    this.postTitle = this.title.getTitle()
    this.postUrl = this.document.URL
    this._bottomSheetRef.dismiss()
  }

  copyLink(): void {
    this.clipboard.copy(this.document.URL)
    this._bottomSheetRef.dismiss(true)
  }
}
