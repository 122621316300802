<header class="bg-transparent z-50 fixed top-0 left-0 right-0 w-full mat-elevation-z1">
    <nav>
        <mat-toolbar color="primary">
            <!--   <button mat-icon-button aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
        -->
            <a mat-button href="/">
                <span class="flex justify-center items-center flex-nowrap">
                    <img class="h-8 w-8" src="assets/img/logo-mecate.svg" alt="">
                    <span class="text-xl pl-2">
                        Mecate Digital
                    </span>
                </span>
            </a>
            <span class="flex-auto"></span>
            <!--
<button mat-icon-button aria-label="Example icon-button with heart icon">
                <mat-icon>favorite</mat-icon>
            </button>

            -->
            <button mat-icon-button aria-label="Example icon-button with share icon" (click)="shareInSocialMedia()">
                <mat-icon>share</mat-icon>
            </button>
        </mat-toolbar>
    </nav>
</header>
<div class="relative top-16">
    <ng-content></ng-content>
</div>