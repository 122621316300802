import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContactHubspot } from '../models/contact-hubspot.model';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
   private _url:string='';
  constructor(private http:HttpClient) { 
    this._url = `${environment.apiHubspot}`;
  }

  getAll(){
    return this.http.get<any>(this._url+'/api/hubspots');
  }

  sendContact(contact: ContactHubspot){
    return this.http.post(`${this._url}/api/hubspots/contact`, contact)
  }
}
