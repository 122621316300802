import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

//Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatSnackBarModule } from '@angular/material/snack-bar'
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    MatSnackBarModule
  ],
  exports: [FormsModule, ReactiveFormsModule, MatBottomSheetModule,MatSnackBarModule],
})
export class RootModule {}
